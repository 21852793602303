import React from 'react'
import unified from 'unified'
import markdown from 'remark-parse'
import addAttributes from 'remark-attr'
import remark2rehype from 'remark-rehype'
import rehype2react, { ComponentProps } from 'rehype-react'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Variant } from '@material-ui/core/styles/createTypography'
import Link, { LinkProps } from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'

import VideoPlayer from '../components/VideoPlayer'
import AudioPlayer from '../components/AudioPlayer'

interface Props {
  className?: string
  content: string
  variantMap?: {
    h1?: Variant
    h2?: Variant
    h3?: Variant
    h4?: Variant
    h5?: Variant
    h6?: Variant
    p?: Variant
  }
  linkProps?: LinkProps
  typographyProps?: TypographyProps
  variantTypographyProps?: {
    [variant in Variant]?: TypographyProps
  }
  paragraphs?: boolean
}

const Markdown: React.FC<Props> = (props) => {
  const {
    className,
    content,
    variantMap,
    paragraphs,
    typographyProps,
    variantTypographyProps,
    linkProps,
  } = props
  const resolvedParagraphs = paragraphs === undefined ? false : paragraphs

  const h1: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.h1 || 'h1' : 'h1'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const h2: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.h2 || 'h2' : 'h2'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const h3: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.h3 || 'h3' : 'h3'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const h4: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.h4 || 'h4' : 'h4'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const h5: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.h5 || 'h5' : 'h5'
    return (
      <Typography paragraph={resolvedParagraphs} variant={variant}>
        {children}
      </Typography>
    )
  }
  const h6: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.h6 || 'h6' : 'h6'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const p: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.p || 'body1' : 'body1'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const ul: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.p || 'body1' : 'body1'
    return (
      <Typography paragraph={resolvedParagraphs} variant={variant} component="ul">
        {children}
      </Typography>
    )
  }
  const ol: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.p || 'body1' : 'body1'
    return (
      <Typography paragraph={resolvedParagraphs} variant={variant} component="ol">
        {children}
      </Typography>
    )
  }
  // TODO: use RouterLink for relative urls
  const a: React.FC<ComponentProps> = (props) => {
    const { children, href, className } = props
    switch (className) {
      case 'audio':
        return (
          <AudioPlayer src={href as string}>
            {(onClick) => (
              <Link onClick={onClick} {...linkProps}>
                {children}
              </Link>
            )}
          </AudioPlayer>
        )
      case 'video':
        return (
          <VideoPlayer src={href as string}>
            {(onClick) => (
              <Link onClick={onClick} {...linkProps}>
                {children}
              </Link>
            )}
          </VideoPlayer>
        )
      default:
        if ((href as string).startsWith('/')) {
          return (
            <Link component={RouterLink} to={href as string} {...linkProps}>
              {children}
            </Link>
          )
        }
        return (
          <Link href={href as string} {...linkProps}>
            {children}
          </Link>
        )
    }
  }

  var parser = unified().use(markdown).use(addAttributes).use(remark2rehype)
  var processor = parser.use(rehype2react, {
    createElement: React.createElement,
    components: {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a,
      ul,
      ol,
    },
  })

  return <Box className={className}>{processor.processSync(content).result as React.ReactNode}</Box>
}

export default Markdown
