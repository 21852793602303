import HeadFITAudioToolBreathingTechniquesV1 from './HeadFITAudioToolBreathingTechniquesV1.mp3'
import HeadFITAudioToolBodyPostureFeedbackV1 from './HeadFITAudioToolBodyPostureFeedbackV1.mp3'

export { default as HeadFITAudioToolBreathingTechniquesV1 } from './HeadFITAudioToolBreathingTechniquesV1.mp3'
export { default as HeadFITAudioToolBodyPostureFeedbackV1 } from './HeadFITAudioToolBodyPostureFeedbackV1.mp3'

export default {
  HeadFITAudioToolBreathingTechniquesV1,
  HeadFITAudioToolBodyPostureFeedbackV1,
}
