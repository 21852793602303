import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'

import { Arrow3RightIcon } from '../icons'

import VideoPlayer from './VideoPlayer'

import { VttTrack } from '*/media_content.yaml'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.white,
    },
    play: {
      margin: theme.spacing(2),
      fontSize: theme.typography.h1.fontSize,
    },
  }),
)

interface Props {
  className?: string
  src: string
  vtt?: VttTrack[]
}

const VideoViewer: React.FC<Props> = (props) => {
  const { className, children, ...videoProps } = props
  const classes = useStyles()

  return (
    <VideoPlayer {...videoProps}>
      {(onClick) => (
        <Box className={clsx(className, classes.root)}>
          <IconButton
            aria-label="play video"
            className={classes.play}
            onClick={onClick}
            color="inherit"
          >
            <Arrow3RightIcon style={{ fontSize: 'inherit' }} color="inherit" />
          </IconButton>
          {children}
        </Box>
      )}
    </VideoPlayer>
  )
}

export default VideoViewer
