import React from 'react'
import { is } from 'ramda'
import Hls from 'hls.js'

import clientContent from '../content'
import { VttTrack } from '*/media_content.yaml'

const { vtt: vttTracks } = clientContent

interface Props
  extends React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
  vtt?: VttTrack[]
}

const Video = React.forwardRef<HTMLVideoElement, Props>((props, forwardedRef) => {
  const { src, autoPlay, vtt, ...otherProps } = props
  const videoRef = React.useRef<HTMLVideoElement>()

  React.useLayoutEffect(() => {
    if (forwardedRef && is(Function)(forwardedRef) && videoRef.current) {
      ;(forwardedRef as (instance: HTMLVideoElement | null) => void)(videoRef.current)
    } else if (forwardedRef && videoRef.current) {
      ;(forwardedRef as React.MutableRefObject<HTMLVideoElement>).current = videoRef.current
    }
  }, [forwardedRef])

  React.useLayoutEffect(() => {
    // // use local file for development
    // if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    //   videoRef.current && (videoRef.current.src = `http://localhost:8000/${src}.mp4`)
    //   videoRef.current?.play()
    //   return
    // }

    let cancel = false
    var hls = new Hls({})
    // hls.startLevel = -1
    hls.loadLevel = 4

    if (videoRef.current && src) {
      let mediaSrc = `/videos/${src}/hls.m3u8`
      // use local file for development
      if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        mediaSrc = `https://${process.env.REACT_APP_MEDIA_DOMAIN}${mediaSrc}`
      }

      if (Hls.isSupported()) {
        hls.loadSource(mediaSrc)
        hls.attachMedia(videoRef.current)
        if (autoPlay) {
          // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
          hls.on(
            Hls.Events.MEDIA_ATTACHED,
            () => !cancel && videoRef.current && videoRef.current.play(),
          )
        }
      } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.src = mediaSrc
        if (autoPlay) {
          videoRef.current.addEventListener(
            'loadedmetadata',
            () => !cancel && videoRef.current && videoRef.current.play(),
          )
        }
      }
    }

    return () => {
      hls.destroy()
      cancel = true
    }
  }, [src, autoPlay])

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video ref={videoRef as React.RefObject<HTMLVideoElement>} {...otherProps}>
      {(vtt || []).map((track, i) => (
        <track {...track} src={vttTracks[track.src as keyof typeof vtt]} default={i === 0} />
      ))}
    </video>
  )
})

export default Video
