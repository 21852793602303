import ThumbnailStill18 from './ThumbnailStill18.png'
import Silhouette002 from './Silhouette002.png'
import ThumbnailStill09 from './ThumbnailStill09.png'
import SocialImage from './SocialImage.png'
import ThumbnailStill08 from './ThumbnailStill08.png'
import HeadsTogether from './HeadsTogether.png'
import IntroThumbnail from './IntroThumbnail.png'
import HeaderHome from './HeaderHome.png'
import MinistryOfDefence from './MinistryOfDefence.png'
import ThumbnailStill11 from './ThumbnailStill11.png'
import ThumbnailStill05 from './ThumbnailStill05.png'
import ThumbnailStill04 from './ThumbnailStill04.png'
import ThumbnailStill10 from './ThumbnailStill10.png'
import ThumbnailStill06 from './ThumbnailStill06.png'
import ThumbnailStill12 from './ThumbnailStill12.png'
import ThumbnailStill13 from './ThumbnailStill13.png'
import ThumbnailStill07 from './ThumbnailStill07.png'
import ThumbnailStill03 from './ThumbnailStill03.png'
import ThumbnailStill17 from './ThumbnailStill17.png'
import ThumbnailStill16 from './ThumbnailStill16.png'
import ThumbnailStill02 from './ThumbnailStill02.png'
import ThumbnailStill14 from './ThumbnailStill14.png'
import ThumbnailStill01 from './ThumbnailStill01.png'
import ThumbnailStill15 from './ThumbnailStill15.png'

export { default as ThumbnailStill18 } from './ThumbnailStill18.png'
export { default as Silhouette002 } from './Silhouette002.png'
export { default as ThumbnailStill09 } from './ThumbnailStill09.png'
export { default as SocialImage } from './SocialImage.png'
export { default as ThumbnailStill08 } from './ThumbnailStill08.png'
export { default as HeadsTogether } from './HeadsTogether.png'
export { default as IntroThumbnail } from './IntroThumbnail.png'
export { default as HeaderHome } from './HeaderHome.png'
export { default as MinistryOfDefence } from './MinistryOfDefence.png'
export { default as ThumbnailStill11 } from './ThumbnailStill11.png'
export { default as ThumbnailStill05 } from './ThumbnailStill05.png'
export { default as ThumbnailStill04 } from './ThumbnailStill04.png'
export { default as ThumbnailStill10 } from './ThumbnailStill10.png'
export { default as ThumbnailStill06 } from './ThumbnailStill06.png'
export { default as ThumbnailStill12 } from './ThumbnailStill12.png'
export { default as ThumbnailStill13 } from './ThumbnailStill13.png'
export { default as ThumbnailStill07 } from './ThumbnailStill07.png'
export { default as ThumbnailStill03 } from './ThumbnailStill03.png'
export { default as ThumbnailStill17 } from './ThumbnailStill17.png'
export { default as ThumbnailStill16 } from './ThumbnailStill16.png'
export { default as ThumbnailStill02 } from './ThumbnailStill02.png'
export { default as ThumbnailStill14 } from './ThumbnailStill14.png'
export { default as ThumbnailStill01 } from './ThumbnailStill01.png'
export { default as ThumbnailStill15 } from './ThumbnailStill15.png'

export default {
  ThumbnailStill18,
  Silhouette002,
  ThumbnailStill09,
  SocialImage,
  ThumbnailStill08,
  HeadsTogether,
  IntroThumbnail,
  HeaderHome,
  MinistryOfDefence,
  ThumbnailStill11,
  ThumbnailStill05,
  ThumbnailStill04,
  ThumbnailStill10,
  ThumbnailStill06,
  ThumbnailStill12,
  ThumbnailStill13,
  ThumbnailStill07,
  ThumbnailStill03,
  ThumbnailStill17,
  ThumbnailStill16,
  ThumbnailStill02,
  ThumbnailStill14,
  ThumbnailStill01,
  ThumbnailStill15,
}
