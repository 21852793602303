import BodyPostureFeedbackENG from './BodyPostureFeedbackENG.vtt'
import BodyPostureFeedbackGRE from './BodyPostureFeedbackGRE.vtt'

export { default as BodyPostureFeedbackENG } from './BodyPostureFeedbackENG.vtt'
export { default as BodyPostureFeedbackGRE } from './BodyPostureFeedbackGRE.vtt'

export default {
  BodyPostureFeedbackENG,
  BodyPostureFeedbackGRE,
}
