import React from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'

import Markdown from '../components/Markdown'
import DocumentTitle from '../components/DocumentTitle'

import { DocumentMediaContent } from '*/media_content.yaml'

interface DocumentContent extends DocumentMediaContent {
  title: string
  content: string
}

interface Props {
  className?: string
  document: DocumentContent
}

const Document: React.FC<Props> = (props) => {
  const { className, document } = props

  return (
    <>
      <Box className={clsx(className)}>
        <DocumentTitle title={document.title} />
        <Markdown
          paragraphs
          content={document.content}
          variantMap={{ h1: 'h4', h2: 'h5', h3: 'h6', h4: 'h6', h5: 'h6', h6: 'h6' }}
        />
      </Box>
    </>
  )
}

export default Document
