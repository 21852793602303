import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { Link as RouterLink } from 'react-router-dom'

import Video from '../components/Video'
import Markdown from '../components/Markdown'

import clientContent from '../content'
import { LogoWithText } from '../svg'
import { Content } from '../types'

const { svg } = clientContent
const logoWithText = svg.LogoWithText || LogoWithText

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.divider,
      position: 'relative',
      zIndex: 0,
      color: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(5, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
      },
    },
    video: {
      position: 'absolute',
      left: '0',
      top: '0',
      right: '0',
      bottom: '0',
      overflow: 'hidden',
      '&>video': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    content: {
      padding: `0 0 0 50%`,
      position: 'relative',
      zIndex: 1,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        width: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 auto',
      },
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
      margin: theme.spacing(3, 0),
      alignSelf: 'stretch',
    },
    text: {
      maxWidth: 400,
    },
    button: {
      marginTop: theme.spacing(3),
    },
  }),
)

interface Props {
  content: Content
}

const Welcome: React.FC<Props> = (props) => {
  const { content } = props
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.video}>
        <Video
          width="auto"
          height="100%"
          src={content.start.video}
          autoPlay
          playsInline
          loop
          muted
        />
      </Box>
      <Box className={classes.content}>
        <img width={200} src={logoWithText} alt={content.name} />
        <Divider className={classes.divider} />
        <Markdown
          className={classes.text}
          paragraphs
          content={content.home.what.body}
          variantMap={{ p: 'body1' }}
        />
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          to={`/home`}
          component={RouterLink}
          size="large"
        >
          {content.general.start}
        </Button>
      </Box>
    </Box>
  )
}

export default Welcome
