import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { O } from 'ts-toolbelt'
import { useMeasure } from 'react-use'

import useWidth from '../hooks/useWidth'

import clientContent from '../content'
import { PeaksGraphic } from '../svg'
import Video from './Video'
import { DocumentMediaContent, ImageRef } from '*/media_content.yaml'

const { svg, images } = clientContent
const peaksGraphic = svg.PeaksGraphic || PeaksGraphic

const smallHeaderHeight = 100
const smallHeaderOverlap = 60
const headerHeight = 500
const headerOverlap = 360
const mountainContainerHeight = 220
const mountainWidth = 180

const useStyles = makeStyles<Theme, { image?: ImageRef; contentHeight: number }>((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.main,
      paddingBottom: theme.spacing(5),
      position: 'relative',
      zIndex: 0,
    },
    bannerBlock: {
      // background: '#ccc',
      backgroundColor: theme.palette.divider,
      marginBottom: -headerOverlap - mountainContainerHeight,
      [theme.breakpoints.down('xs')]: {
        height: smallHeaderHeight,
        marginBottom: -smallHeaderOverlap,
      },
      position: 'relative',
      zIndex: 0,
      overflow: 'hidden',
    },
    banner: {
      margin: '0 auto',
      width: 1700,
      maxWidth: '100%',
      backgroundImage: ({ image }) => `url(${image && images[image as keyof typeof images]})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'none',
      backgroundPosition: 'center',
      height: headerHeight,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      position: 'relative',
      '&>video': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    mountainBlock: {
      position: 'relative',
      height: mountainContainerHeight,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      alignItems: 'flex-end',
    },
    mountainContainer: {
      width: 1024 + mountainWidth * 2 - theme.spacing(2),
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
    },
    preText: {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, 0)',
      top: headerHeight - headerHeight / 1.5 - 40,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    paper: {
      width: 1024,
      maxWidth: '100%',
      margin: '0 auto',
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      zIndex: 1,
      height: ({ contentHeight }) => contentHeight + 2 * theme.spacing(3),
      transition: theme.transitions.create('height', {
        duration: theme.transitions.duration.standard,
      }),
    },
  }),
)

export interface StandardPageMediaContent extends DocumentMediaContent {
  [key: string]: any
}

interface Props {
  classes?: O.Optional<ClassNameMap<'root' | 'paper'>>
  media: StandardPageMediaContent
}

const StandardPage: React.FC<Props> = (props) => {
  const { classes: classesOverrides, media, children } = props
  const width = useWidth()
  const [ref, { height: contentHeight }] = useMeasure()
  const classes = useStyles({
    image: media.bannerImage,
    contentHeight: contentHeight < Infinity && contentHeight > 0 ? contentHeight : 1000,
  })

  return (
    <Box className={clsx(classesOverrides?.root, classes.root)}>
      <Box className={classes.bannerBlock}>
        <Box className={classes.banner}>
          {media.bannerVideo && width !== 'xs' && (
            <Video
              height="100%"
              width="auto"
              src={media.bannerVideo}
              playsInline
              autoPlay
              loop
              muted
            />
          )}
        </Box>
        <Box className={classes.mountainBlock}>
          <Box className={classes.mountainContainer}>
            <img
              className={classes.mountain}
              width={mountainWidth}
              src={peaksGraphic}
              alt={'Mountain peaks'}
            />
            <img
              className={classes.mountain}
              width={mountainWidth}
              src={peaksGraphic}
              alt={'Mountain peaks'}
            />
          </Box>
        </Box>
      </Box>
      <Paper className={clsx(classesOverrides?.paper, classes.paper)}>
        <Box {...{ ref }}>{children}</Box>
      </Paper>
    </Box>
  )
}

export default StandardPage
