import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { Link as RouterLink } from 'react-router-dom'

import Markdown from '../components/Markdown'

import clientContent from '../content'
import { Content } from '../types'
import { ContentStructure } from '*/structure.yaml'

const { images } = clientContent

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    links: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    link: {
      margin: theme.spacing(0, 1),
    },
  }),
)

interface Props {
  className?: string
  contentStructure: ContentStructure
  content: Content
}

const Footer: React.FC<Props> = (props) => {
  const { className, contentStructure, content } = props
  const classes = useStyles()

  return (
    <Container role="contentinfo" className={clsx(className, classes.root)}>
      <Markdown paragraphs content={content.footer.supportedByTitle} variantMap={{ p: 'h6' }} />
      <Box>
        {content.footer.supportedBy.map((s) => (
          <Link key={s.url} href={s.url}>
            <img height={80} src={images[s.image as keyof typeof images]} alt={s.url} />
          </Link>
        ))}
      </Box>
      <Box className={classes.links}>
        {contentStructure.legals.map((legalId) => (
          <Link
            color="inherit"
            className={classes.link}
            key={legalId}
            to={`/legal/${legalId}`}
            component={RouterLink}
          >
            <Typography variant="caption">{content.legals[legalId].title}</Typography>
          </Link>
        ))}
      </Box>
      <Markdown content={content.footer.copyright} variantMap={{ p: 'caption' }} />
    </Container>
  )
}

export default Footer
