import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import Modal from './Modal'
import Video from './Video'

import { VttTrack } from '*/media_content.yaml'

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 1000,
      maxWidth: '90%',
      margin: '0 auto',
    },
  }),
)

interface Props {
  children: (onClick: () => void) => React.ReactNode
  src: string
  vtt?: VttTrack[]
}

const VideoPlayer: React.FC<Props> = (props) => {
  const { children, ...videoProps } = props
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {children(handleOpen)}
      <Modal
        aria-labelledby="audio"
        aria-describedby="audio"
        className={classes.modal}
        open={open}
        onClose={handleClose}
      >
        <Video width="100%" height="auto" {...videoProps} autoPlay controls />
      </Modal>
    </>
  )
}

export default VideoPlayer
